import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: '12px',
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.08)',
  transition: 'transform 0.2s, box-shadow 0.2s',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 6px 24px rgba(0, 0, 0, 0.12)'
  },
  position: 'relative',
  overflow: 'visible',
  backgroundColor: theme.palette.background.paper,
}));

const OpenItemRibbon = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: '12px',
  right: '-30px',
  width: '120px',
  padding: '4px 0',
  backgroundColor: theme.palette.primary.main,
  color: 'white',
  textAlign: 'center',
  fontSize: '0.75rem',
  fontWeight: 'bold',
  transform: 'rotate(45deg)',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
  zIndex: 1,
}));

const DetailChip = styled(Chip)(({ theme }) => ({
  marginRight: theme.spacing(0.5),
  marginBottom: theme.spacing(0.5),
  fontSize: '0.75rem',
  height: '24px',
}));

function MenuItemCard({ item, onEdit, onDelete }) {
  const getExpiryText = (expiryDate) => {
    try {
      const expiry = new Date(expiryDate);
      if (isNaN(expiry)) return { text: 'Invalid date', isExpired: false };

      const now = new Date();
      const isExpired = expiry < now;

      return {
        text: expiry.toLocaleDateString('en-US', {
          weekday: 'short',
          day: '2-digit',
          month: 'short',
          year: 'numeric'
        }),
        isExpired
      };
    } catch {
      return { text: 'Invalid date', isExpired: false };
    }
  };

  const expiryInfo = item.open_item_expiry ? getExpiryText(item.open_item_expiry) : null;

  return (
    <Box sx={{ position: 'relative', my: 2 }}>
      {item.is_open_item === 1 && (
        <OpenItemRibbon>OPEN ITEM</OpenItemRibbon>
      )}

      <StyledCard>
        <CardContent sx={{ pt: 3 }}>
          <Stack spacing={1.5}>
            <Typography variant="h6" component="h3" sx={{ 
              fontWeight: 600,
              color: 'text.primary',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}>
              {item.menu_name}
              <Box sx={{ color: 'primary.main', fontSize: '1.25rem', fontWeight: 700 }}>
                ₹{item.price}
              </Box>
            </Typography>

            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
              <DetailChip 
                label={`CGST: ${item.cgst}%`} 
                size="small" 
                variant="outlined" 
                color="success" 
              />
              <DetailChip 
                label={`SGST: ${item.sgst}%`} 
                size="small" 
                variant="outlined" 
                color="success" 
              />
              {item.code && (
                <DetailChip 
                  label={`Code: ${item.code}`} 
                  size="small" 
                  variant="outlined" 
                />
              )}
              {item.section && (
                <DetailChip 
                  label={`Section: ${item.section}`} 
                  size="small" 
                  variant="outlined" 
                />
              )}
              {item.cuisine && (
                <DetailChip 
                  label={`Cuisine: ${item.cuisine}`} 
                  size="small" 
                  variant="outlined" 
                />
              )}
            </Box>

            {item.is_open_item === 1 && expiryInfo && (
              <Typography variant="caption" sx={{
                display: 'inline-block',
                color: expiryInfo.isExpired ? 'error.main' : 'success.main',
                fontWeight: 500,
                mt: 0.5
              }}>
                {expiryInfo.isExpired ? 'Expired on ' : 'Expires on '}
                {expiryInfo.text}
                {expiryInfo.isExpired && (
                  <Chip 
                    label="EXPIRED" 
                    size="small" 
                    color="error" 
                    sx={{ ml: 1, fontSize: '0.65rem' }} 
                  />
                )}
              </Typography>
            )}

            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'flex-end', 
              mt: 1,
              '& .MuiIconButton-root': {
                transition: 'all 0.2s',
                '&:hover': {
                  transform: 'scale(1.1)'
                }
              }
            }}>
              <IconButton
                onClick={onEdit}
                aria-label="edit"
                size="small"
                sx={{ color: 'primary.main' }}
              >
                <EditIcon fontSize="small" />
              </IconButton>
              <IconButton
                onClick={onDelete}
                aria-label="delete"
                size="small"
                sx={{ color: 'error.main' }}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Box>
          </Stack>
        </CardContent>
      </StyledCard>
    </Box>
  );
}

export default MenuItemCard;